import { sentryWarn } from '@features/errors';
import type { CombinedDrugBrandResponse } from './drugApi.gen';

export type LabelSearchCountry = 'us' | 'fda' | 'eu' | 'combined';

export type SourceKey = keyof CombinedDrugBrandResponse;

export type RegionString = 'US' | 'UK' | 'EU';

const mapSourceToRegion: Record<SourceKey, RegionString> = {
    euEma: 'EU',
    ukEmc: 'UK',
    fdaSpl: 'US',
    drugsAtFda: 'US'
}

export type SourceAbbreviation = 'spl' | 'fda' | 'eu' | 'uk';

const mapSourceToAbbreviation: Record<SourceKey, SourceAbbreviation> = {
    euEma: 'eu',
    ukEmc: 'uk',
    fdaSpl: 'spl',
    drugsAtFda: 'fda'
}

/**
 * Array of source keys, in order of importance.
 */
export const sourcePriority: SourceKey[] = [
    'fdaSpl',
    'drugsAtFda',
    'euEma',
    'ukEmc'
];

export const sourceToRegion = (source: SourceKey): RegionString => {
    return mapSourceToRegion[source];
}

/**
 * UK and EU have only one source, but US has multiple.
 * Will use SPL unless called with us = 'drugsAtFda'.
 */
export const regionToSource = (region: RegionString, us: SourceKey = 'fdaSpl'): SourceKey => {
    switch (region) {
        case 'US':
            return us;
        case 'UK':
            return 'ukEmc';
        case 'EU':
            return 'euEma';
        default:
            return us;
    }
}

export const sourceAbbreviation = (source: SourceKey): SourceAbbreviation => {
    return mapSourceToAbbreviation[source];
}

export const abbreviationToSource = (abbr: SourceAbbreviation | string): SourceKey => {
    return Object.keys(mapSourceToAbbreviation).find(key => mapSourceToAbbreviation[key] === abbr) as SourceKey;
}

/**
 * Can the history endpoint be called.
 */
export const supportsHistory = (source: SourceKey) =>
    source === 'fdaSpl' || source === 'drugsAtFda';

/**
 * Does the root package have a version identifier.
 */
export const supportsVersion = (source: SourceKey) =>
    source === 'fdaSpl' || source === 'drugsAtFda';

export const sourceDisplayText = (source: SourceKey) => {
    switch (source) {
        case 'drugsAtFda':
            return 'FDA';
        case 'fdaSpl':
            return 'SPL';
        case 'euEma':
            return 'EU';
        case 'ukEmc':
            return 'UK';
        default:
            return '';
    }
}

export interface BrandAndSource {
    brandName: string;
    source: SourceKey;
}

/**
 * Combine region and brandName into a unique key.
 * Do this with a function to ensure that it's consistent everywhere.
 * Note: does not distinguish between SPL and FDA.
 */
export const regionalBrandKey = (region: RegionString | string, brandName: string) => {
    return `${region}-${brandName}`;
}

export interface RegionalBrandObject {
    region: RegionString;
    brandName: string;
    key: string;
    activeIngredients?: string;
}

/**
 * Unparse keys from the format `${region}-${brandName}`
 * Note: Avoid using this and prefer storing object instead of parsing the strings.
 */
export const drugObjectFromKey = (key: string): RegionalBrandObject => {
    const pos = key.indexOf('-');
    if (pos < 0) {
        sentryWarn(`Invalid drug key ${key}. Should have the format "{region}-{brandName}"`);
        return {
            brandName: key,
            region: 'US',
            key
        }
    }
    const region = key.substring(0, pos) as RegionString;
    const brandName = key.substring(pos + 1);
    return {
        region,
        brandName,
        key
    }
}
